import { useState } from "react";
import "./styles.css";

import icon from "../../assets/icontel.png";

const CtaTel = () => {
  const [text, setText] = useState("(21) 96891-7581");

  return (
    <button id="ctaTel">
      <a href="tel:968917581">
        <figure>
          <img src={icon} alt="Ícone do Telefone" />
        </figure>
        <p>{text}</p>
      </a>
    </button>
  );
};

export default CtaTel;
