import "./styles.css";

import header3_1 from "../../assets/header3_1.png";
import header3_2 from "../../assets/header3_2.png";
import header3_3 from "../../assets/header3_3.png";

import CtaWpp from "../../components/CTAWpp";

const Header = () => {
  return (
    <header id="header">
      <section className="header1">
        <article className="container">
          <h1 className="title">
            Especialista em Rejuvenescimento Facial e Corporal
          </h1>

          <p className="text">
            Transforme o seu corpo de dentro para fora, revigorando a aparência
            e a autoestima através da estética avançada.
          </p>

          <CtaWpp />
        </article>
      </section>

      <section className="header2">
        <article className="container vertical">
          <h2 className="title">
            REJUVENESCIMENTO ATRAVÉS DA ESTÉTICA AVANÇADA
          </h2>

          <p className="text">
            A Estética Regenerativa é uma abordagem revolucionária no campo
            da estética que se concentra não apenas em melhorar a aparência
            externa, mas também em promover a regeneração celular e a saúde da
            pele de dentro para fora.
          </p>

          <p className="text text2">
            Nossas técnicas e procedimentos visam o rejuvenescimento com
            naturalidade.
          </p>
        </article>
      </section>

      <section className="header3">
        <article className="container vertical">
          <div className="gallery">
            <div className="item">
              <figure className="top">
                <img src={header3_1} alt="Estética avançada" />
              </figure>

              <div className="bottom">
                <p className="item_title">Estética avançada</p>

                <p className="item_text">
                  Os melhores procedimentos para aprimorar sua beleza.
                </p>
              </div>
            </div>

            <div className="item">
              <figure className="top">
                <img src={header3_2} alt="HARMONIZAÇÃO FACIAL E CORPORAL" />
              </figure>

              <div className="bottom">
                <p className="item_title">HARMONIZAÇÃO FACIAL E CORPORAL</p>

                <p className="item_text">Elevando a sua beleza e autoestima.</p>
              </div>
            </div>

            <div className="item">
              <figure className="top">
                <img src={header3_3} alt="Saúde integrativa" />
              </figure>

              <div className="bottom">
                <p className="item_title">Saúde integrativa</p>

                <p className="item_text">
                  Tratando o corpo como um todo de dentro para fora.
                </p>
              </div>
            </div>
          </div>

          <CtaWpp />
        </article>
      </section>
    </header>
  );
};

export default Header;
