import React from "react";
import "./styles.css";

import logo_rodape from "../../assets/logo_rodape.png";
import iconwpp2 from "../../assets/iconwpp2.png";
import icontel2 from "../../assets/icontel2.png";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer1">
        <div className="container">
          <figure className="logo">
            <img src={logo_rodape} alt="Logo" />
          </figure>

          <div className="middle1">
            <p className="item_title">Endereço:</p>

            <p className="item_text">
              <a
                href="https://maps.app.goo.gl/fhWWGthhb1XrdqiV7"
                target="_blank"
                rel="noreferrer"
              >
                Rua ministro Otávio kelly, nº 337, sala 801, Icarai, Niterói -
                RJ.
              </a>
            </p>

            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3675.231494795537!2d-43.1053538675424!3d-22.904830812715932!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9983fa487c0001%3A0xdcfe30f118ae16d8!2sR.%20Min.%20Ot%C3%A1vio%20Kelly%2C%20337%20-%20sala%20801%20-%20Jardim%20Icara%C3%AD%2C%20Niter%C3%B3i%20-%20RJ%2C%2024220-300!5e0!3m2!1spt-BR!2sbr!4v1724881737344!5m2!1spt-BR!2sbr"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="map"
              className="map"
            ></iframe>
          </div>

          <div className="contacts">
            <p className="item_title">Contatos:</p>

            <div className="ctas">
              <a
                href="http://wa.me/+5521968917581?text=Olá,%20Gostaria%20de%20agendar%20meu%20tratamento"
                target="_blank"
                rel="noreferrer"
                className="cta"
              >
                <figure>
                  <img src={iconwpp2} alt="Ícone do Whatsapp" />
                </figure>

                <p className="item_text">(21) 96891-7581</p>
              </a>

              <a href="tel:968917581" className="cta">
                <figure>
                  <img src={icontel2} alt="Ícone do Telefone" />
                </figure>

                <p className="item_text">(21) 96891-7581</p>
              </a>
            </div>
          </div>

          <div className="right">
            <p className="item_title">Horário de Atendimento:</p>

            <p className="item_text">
              De segunda a sexta-feira. <br />
              Das 9:00h às 17:00h.
            </p>
          </div>
        </div>
      </div>

      <div className="footer2">
        <div className="container">
          <p className="text">
            Dra. Priscila Pacheco Estética Avançada © 2024 - Todos os direitos
            reservados
          </p>

          <p className="text2">
            Desenvolvido por{" "}
            <a href="https://magencia.com.br" target="_blank" rel="noreferrer">
              M Agência Digital
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
